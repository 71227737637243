import React from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Partner from '../components/Route/Partners/Partner';
import About from '../components/Route/About/About';
import InfoCard from '../components/Route/InfoCard/InfoCard';
import Clients from '../components/Route/Clients/Clients';

const AboutPage = () => {
  return (
    <div>
      <Header activeHeading={1} />
      <Clients />
      <About />
      <Partner />
      <InfoCard />
      <Footer />
    </div>
  )
}

export default AboutPage