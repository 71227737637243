import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Subservice = () => {
    let { serviceId, subserviceId } = useParams();
    const [subserviceDetails, setSubserviceDetails] = useState(null);

    useEffect(() => {
        const fetchSubserviceDetails = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            try {
                const response = await fetch(`${apiUrl}/service/service/${serviceId}/subservice/${subserviceId}`);
                if (response.ok) {
                    const data = await response.json();
                    setSubserviceDetails(data.subService);
                } else {
                    // handle errors
                    console.error('Could not fetch subservice details');
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        fetchSubserviceDetails();
    }, [serviceId, subserviceId]);

    return (
        <div className="container mx-auto p-4">
            {subserviceDetails ? (
                <div className="flex flex-col">
                    <div className="mb-4">
                        <h2 className="text-2xl font-bold">{subserviceDetails.title}</h2>
                        <div className="prose lg:prose-xl list-disc list-inside my-6"
                            dangerouslySetInnerHTML={{ __html: subserviceDetails.content }}>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        {subserviceDetails.photos?.map((photo, index) => (
                            <img key={index} src={photo} alt={`Subservice detail ${index}`} className="object-cover h-64 w-full rounded-md shadow-lg" />
                        ))}
                    </div>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default Subservice;
