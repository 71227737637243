import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const NewsSingle = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/news/news/${id}`);
        if (!response.ok) {
          throw new Error('Something went wrong');
        }
        const data = await response.json();
        setNewsItem(data.news);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchNews();
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      {newsItem && (
        <div>
          <h1 className="text-2xl font-bold mb-4">{newsItem.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: newsItem.content }} className="mb-4" />
          {newsItem.photo && (
            <img src={newsItem.photo} alt={newsItem.title}
              className="w-75% h-75% object-cover rounded-lg" />
          )}
        </div>
      )}
    </div>
  );


};

export default NewsSingle;
