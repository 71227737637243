import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NewsList = () => {
    const [newsList, setNewsList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/news/getnews`);
                if (!response.ok) {
                    throw new Error('Failed to fetch news');
                }
                const data = await response.json();
                setNewsList(data.newsArticles);
            } catch (error) {
                console.error('There was a problem fetching news:', error);
            }
        };

        fetchNews();
    }, []);

    return (
        <div className='container mx-auto p-4'>
            <div className="flex flex-col items-center">
                {newsList.map(news => (
                    <div key={news._id} className="w-full max-w-4xl bg-white shadow-lg rounded-lg overflow-hidden my-5">
                        <img src={news.photo} alt={news.title} className="w-full h-64 object-cover object-center" />
                        <div className="p-4">
                            <h2 className="font-bold text-xl mb-2">{news.title}</h2>
                            <div className="text-gray-700 text-base" dangerouslySetInnerHTML={{ __html: news.content.substring(0, 100) + '...' }}></div>
                            <button
                                onClick={() => navigate(`/news/${news._id}`)}
                                className="mt-4 btn custom-btn text-white">
                                Read More
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NewsList;
