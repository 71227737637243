import React from 'react';
import Qualitrol from '../../../assets/partners/qualitrol.png';
import DMS from '../../../assets/partners/dms.png';
import Meatest from '../../../assets/partners/meatest.png';
import Hightest from '../../../assets/partners/hightest.png';
import FLIR from '../../../assets/partners/flir.jpg';
import Schneider from '../../../assets/partners/Schneider_Electric_Logo.png';

const Partner = () => {
    const partners = [
        { id: 1, name: 'Qualitrol', image: Qualitrol },
        { id: 2, name: 'Partner 2', image: DMS },
        { id: 3, name: 'Partner 3', image: Meatest },
        { id: 4, name: 'Partner 4', image: Hightest },
        { id: 5, name: 'FLIR', image: FLIR },
        { id: 6, name: 'Partner 6', image: Schneider }
    ];

    return (
        <div className='container'>
            <div className="bg-white rounded-lg shadow-lg overflow-hidden p-4 mt-8">
                <div className="flex items-center mb-2">
                    <h2 className="text-lg font-semibold mr-2">Our<br />Partners</h2>
                    <div className="flex flex-wrap justify-center">
                        {partners.map(partner => (
                            <div key={partner.id} className="mx-4 my-2">
                                <img src={partner.image} alt={partner.name} className="w-32 h-auto" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partner;
