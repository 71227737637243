import React from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Contact from '../components/Route/Contact/Contact';
import Clients from '../components/Route/Clients/Clients';
import CTA from '../components/Route/CTA/CTA';

const ContactPage = () => {
    return (
        <div>
          <Header activeHeading={4} />
          <Contact />
          <Clients />
          <CTA/>
          <Footer />
        </div>
      )
}

export default ContactPage