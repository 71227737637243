import React from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Clients from '../components/Route/Clients/Clients';
import NewsSingle from '../components/Route/NewsSIngle/NewsSingle';

const NewsPage = () => {
    return (
        <div>
            <Header activeHeading={4} />
            <NewsSingle />
            <Clients />
            <Footer />
        </div>
    )
}

export default NewsPage