import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
    const socialMediaLinks = {
        linkedin: "https://www.linkedin.com/company/cbmengineering/",
    };

    const googleMapsLink = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24367.607477783536!2d49.8690495!3d40.416411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403062a8914691f3%3A0xe936994ed9e8ac30!2sSTN+Biznes+Mərkəzi%2C+PSG+Kapital!5e0!3m2!1sen!2s!4v1636292344000!5m2!1sen!2s";

    return (
        <div className="relative container mx-auto mt-10 p-4">
            <div className="rounded-xl overflow-hidden shadow-lg relative">
                <iframe
                    className="w-full"
                    style={{ height: '500px' }}
                    src={googleMapsLink}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
                <div className="absolute bottom-0 mb-[0%] left-1/2 transform -translate-x-1/2 bg-white rounded-lg shadow-2xl p-4 z-10 w-full md:max-w-xl">
                    <h2 className="text-lg font-bold mb-2">Stay connected</h2>
                    <p className="mb-2 text-sm">
                        Or you could also visit us! We will get to know you and help you learn more about us.
                    </p>
                    <button className="btn custom-btn text-white text-xs mb-2">Contact with us</button>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 text-xs">
                        <div>
                            <FontAwesomeIcon icon={faClock} className="mr-1" />
                            <span>08:30 - 17:30</span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faPhoneAlt} className="mr-1" />
                            <span>+994555050601</span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faPhoneAlt} className="mr-1" />
                            <span>+994123110230</span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                            <a href="mailto:office@cbmes.com" className="text-xs">office@cbmes.com</a>
                        </div>
                        <div className="flex justify-center sm:justify-start">
                            <a href={socialMediaLinks.linkedin} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedinIn} className="mx-1" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
