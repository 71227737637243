import React from 'react';
import Partner3Image from '../../../assets/clients/1.png';
import Partner2Image from '../../../assets/clients/azerenerji.png';
import Partner1Image from '../../../assets/clients/bp.png'
import Partner4Image from '../../../assets/clients/cnim.png';
import Partner5Image from '../../../assets/clients/nov.png';
import Partner6Image from '../../../assets/clients/aa.png';
import Partner7Image from '../../../assets/clients/turanDrilling.png';
import Partner8Image from '../../../assets/clients/caspian.png';
import Partner9Image from '../../../assets/clients/socarpolymer.png';
import Partner10Image from '../../../assets/clients/midsteam.jpg';

const ClientCard = ({ image, altText }) => (
    <div className="card bg-base-100 shadow-xl m-2 flex justify-center items-center" style={{ height: '150px', width: '200px' }}>
        <figure className="px-10 pt-10 h-full w-full flex justify-center items-center">
            <img
                src={image}
                alt={altText}
                className="max-h-full max-w-full object-contain"
            />
        </figure>
    </div>
);

const Clients = () => {
    const clients = [
        { id: 1, name: 'Partner 1', image: Partner1Image },
        { id: 9, name: 'Partner 9', image: Partner9Image },
        { id: 10, name: 'Partner 10', image: Partner10Image },
        { id: 2, name: 'Partner 2', image: Partner2Image },
        { id: 3, name: 'Partner 3', image: Partner3Image },
        { id: 4, name: 'Partner 4', image: Partner4Image },
        { id: 5, name: 'Partner 5', image: Partner5Image },
        { id: 6, name: 'Partner 6', image: Partner6Image },
        { id: 7, name: 'Partner 7', image: Partner7Image },
        { id: 8, name: 'Partner 8', image: Partner8Image },
    ];

    return (
        <div className='container'>
            <div className="py-10">
                <h2 className="text-3xl font-bold text-center mb-10">Clients</h2>
                <div className="flex flex-wrap justify-center items-center">
                    {clients.map(partner => (
                        <ClientCard key={partner.id} image={partner.image} altText={partner.name} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Clients;
