import React from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import DataCreate from '../components/Route/DataCreate/DataCreate';

const DataCreatePage  = () => {
    return (
        <div>
          <DataCreate />
        </div>
      )
}

export default DataCreatePage 