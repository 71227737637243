import React from 'react';
import { useNavigate } from 'react-router-dom';
import conditionBased from '../../../assets/services/conditionbased.jpg';
import chemlab from '../../../assets/services/chemlab.jpg';
import calibration from '../../../assets/services/calibration.jpg';

export default function Services() {
    const navigate = useNavigate();

    const serviceDetails = [
        { id: '661ff6141c139c8f03da30b8', title: 'Diagnostic & Maintenance Services', image: conditionBased },
        { id: '6624f287f096108ef2c9a7e3', title: 'Calibration Services', image: calibration },
        { id: '6624f818f096108ef2c9a90b', title: 'Chemical Laboratory Services', image: chemlab },
    ];

    const handleCardClick = (serviceId) => {
        navigate(`/service/${serviceId}`);
    };

    return (
        <section className='py-10 md:py-16'>
            <div className='container'>
                <div className='text-center'>
                    <h2 className='text-2xl sm:text-4xl font-bold mb-4'>What We Do</h2>
                    <p className='text-lg sm:text-lg mb-6 md:mb-14'>
                        Almost all quality assurance standards specify regular control and monitoring for measuring instruments.
                        Another significant reason to guarantee the precision of your measuring devices is the sense of safety.
                        You can only trust the accuracy of your measurements if you know your equipment are operating properly.
                        This confidence is essential for both your business and your customers.
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {serviceDetails.map((service) => (
                        <div key={service.id} className="card bg-base-100 shadow-xl image-full relative cursor-pointer"
                            onClick={() => handleCardClick(service.id)}>
                            <div className="overlay absolute inset-0 bg-black bg-opacity-5 flex justify-center items-center rounded-lg">
                                <h2 className="card-title text-white z-10">{service.title}</h2>
                            </div>
                            <figure>
                                <img src={service.image} alt="Service Image" className="rounded-lg" />
                            </figure>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
