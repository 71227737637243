import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AboutPage, HomePage, ContactPage, DataCreatePage, SubservicePage, NewsPage, NewsAllPage, ServiceListPage } from './Routes.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./App.css";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/service/:serviceId/subservice/:subserviceId' element={<SubservicePage />} />
          <Route path='/service/:serviceId' element={<ServiceListPage />} />
          <Route path='/news/:id' element={<NewsPage />} />
          <Route path='/news' element={<NewsAllPage />} />
          <Route path='/create' element={<DataCreatePage />} />
        </Routes>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </BrowserRouter>
    </>
  )
}

export default App