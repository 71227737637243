import React from 'react';
import { Link } from 'react-router-dom'; // Ensure Link is imported from react-router-dom

export default function Footer() {
    // Define service details as provided
    const serviceDetails = [
        { id: '661ff6141c139c8f03da30b8', title: 'Diagnostic & Maintenance Services', image: 'conditionBased' },
        { id: '6624f287f096108ef2c9a7e3', title: 'Calibration Services', image: 'calibration' },
        { id: '6624f818f096108ef2c9a90b', title: 'Chemical Laboratory Services', image: 'chemlab' },
    ];

    return (
        <footer className='bg-base-200 text-base-content'>
            <div className='container'>
                <div className="footer py-10 md:py-16 grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div>
                        <span className="footer-title">Company</span>
                        <Link to="/about" className="link link-hover">About us</Link>
                        <Link to="/contact" className="link link-hover">Contact</Link>
                        <Link to="/news" className="link link-hover">News</Link>
                    </div>
                    <div>
                        <span className="footer-title">Legal</span>
                        <a href="#!" className="link link-hover">Terms of use</a>
                        <a href="#!" className="link link-hover">Privacy policy</a>
                        <a href="https://cbmeservices-my.sharepoint.com/:b:/g/personal/admin_cbmes_com/EaFbcx_L9cJBp6dNNcncfxYBVFhcLO9TgF9W_lV8yrTylw?e=Fw2dPu" target="_blank" className="link link-hover">Quality Assurance</a>
                    </div>
                    <div>
                        <span className="footer-title">Services</span>
                        {serviceDetails.map((service) => (
                            <Link key={service.id} to={`/service/${service.id}`} className="link link-hover">
                                {service.title}
                            </Link>
                        ))}
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row items-center border-t border-base-300 py-4 gap-2'>
                    <div className="flex-grow text-center sm:text-start">
                        <p>© 2024 CBM Engineering LLC, All rights reserved.</p>
                    </div>
                    <div className="grid grid-flow-col gap-4">
                        <a className='link link-secondary' style={{ color: '#633b81' }} target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/company/cbmengineering/'><i className='bi bi-linkedin text-xl'></i></a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
