import React from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Clients from '../components/Route/Clients/Clients';
import ServiceList from '../components/Route/ServiceList/ServiceListPage';
import CTA from '../components/Route/CTA/CTA';

const ServiceListPage = () => {
    return (
        <div>
            <Header activeHeading={4} />
            <ServiceList />
            <Clients />
            <CTA/>
            <Footer />
        </div>
    )
}

export default ServiceListPage