import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function News() {
    const [newsData, setNewsData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/news/getnews`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setNewsData(data.newsArticles);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        fetchNews();
    }, []);

    const handleReadMore = (id) => {
        navigate(`/news/${id}`);
    };

    return (
        <div className='py-10 md:py-16 bg-gray-200'>
            <div className='container'>
                <div className='text-center'>
                    <h2 className='text-2xl sm:text-4xl font-bold mb-4'>Company News</h2>
                    <p className='text-lg sm:text-xl mb-6 md:mb-14'>
                        Read the latest news related to our company
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {newsData.map((news, index) => (
                        <div key={index} className="card bg-base-100 shadow-xl">
                            <figure><img src={news.photo || 'default-image-url.jpg'} alt="News" className="rounded-box" /></figure>
                            <div className="card-body">
                                <h2 className="card-title text-lg font-bold mb-2">{news.title}</h2>
                                <p>{news.description}</p>
                                <div className="card-actions justify-end">
                                    <button
                                        className="btn custom-btn text-white"
                                        onClick={() => handleReadMore(news._id)}>
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
