import React from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Subservice from '../components/Route/Subservice/Subservice';
import Clients from '../components/Route/Clients/Clients';

const SubservicePage = () => {
    return (
        <div>
            <Header activeHeading={4} />
            <Subservice />
            <Clients />
            <Footer />
        </div>
    )
}

export default SubservicePage