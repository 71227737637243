import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'

const DataCreatePage = () => {
    const [services, setServices] = useState([]);
    const [newServiceName, setNewServiceName] = useState('');
    const [selectedServiceId, setSelectedServiceId] = useState('');
    const [subService, setSubService] = useState({ title: '', content: '', photos: [] });
    const [news, setNews] = useState({ title: '', content: '', photo: null });

    useEffect(() => {
        const fetchServices = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/service/all-services`);
            const data = await response.json();
            setServices(data.services);
            if (data.services.length > 0) {
                setSelectedServiceId(data.services[0]._id);
            }
        };
        fetchServices();
    }, []);

    const handleServiceCreation = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await fetch(`${apiUrl}/service/create-service`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: newServiceName }),
            });
            const newService = await response.json();
            setServices([...services, newService]);
            setNewServiceName('');
        } catch (error) {
            console.error('Failed to create service', error);
        }
    };

    const handleContentChange = value => {
        setSubService({ ...subService, content: value });
    };

    const handleSubServiceCreation = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        const formData = new FormData();
        formData.append('title', subService.title);
        formData.append('content', subService.content);
        subService.photos.forEach(photo => formData.append('photos', photo));

        try {
            const response = await fetch(`${apiUrl}/service/add-subservice/${selectedServiceId}`, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                alert('Sub-service added successfully');
                setSubService({ title: '', content: '', photos: [] });
            } else {
                throw new Error(`Failed to add sub-service, status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error adding sub-service', error);
        }
    };

    const handleNewsCreation = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        const formData = new FormData();
        formData.append('title', news.title);
        formData.append('content', news.content);
        if (news.photo) {
            formData.append('photo', news.photo);
        }

        try {
            const response = await fetch(`${apiUrl}/news/create`, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                alert('News added successfully');
                setNews({ title: '', content: '', photo: null });
            } else {
                throw new Error(`Failed to add news, status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error adding news', error);
        }
    };

    const handlePhotoChange = (e) => {
        setSubService({ ...subService, photos: [...subService.photos, ...e.target.files] });
    };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Create New Service</h2>
            <form onSubmit={handleServiceCreation}>
                <input
                    type="text"
                    value={newServiceName}
                    onChange={(e) => setNewServiceName(e.target.value)}
                    placeholder="Service Name"
                    required
                    className="input input-bordered w-full max-w-xs mb-4"
                />
                <button type="submit" className="btn btn-primary">Create Service</button>
            </form>

            <h2 className="text-2xl font-bold mt-8 mb-4">Add Sub-Service</h2>
            <form onSubmit={handleSubServiceCreation}>
                <select value={selectedServiceId} onChange={(e) => setSelectedServiceId(e.target.value)} className="select select-bordered w-full max-w-xs mb-4">
                    {services.map(service => (
                        <option key={service._id} value={service._id}>{service.name}</option>
                    ))}
                </select>
                <input
                    type="text"
                    value={subService.title}
                    onChange={(e) => setSubService({ ...subService, title: e.target.value })}
                    placeholder="Sub-Service Title"
                    required
                    className="input input-bordered w-full max-w-xs mb-4"
                />
                <ReactQuill
                    theme="snow"
                    value={subService.content}
                    onChange={handleContentChange}
                />
                {subService.photos.map((photo, index) => (
                    <div key={index} className="mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Upload photo {index + 1}
                        </label>
                        <input
                            type="file"
                            onChange={handlePhotoChange}
                            className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 and cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent" multiple />
                    </div>
                ))}
                <button type="button" onClick={() => setSubService({ ...subService, photos: [...subService.photos, ''] })} className="btn btn-secondary mb-4">Add Another Photo</button>
                <button type="submit" className="btn btn-success">Add Sub-Service</button>
            </form>

            <h2 className="text-2xl font-bold mt-8 mb-4">Add News</h2>
            <form onSubmit={handleNewsCreation}>
                <input
                    type="text"
                    value={news.title}
                    onChange={(e) => setNews({ ...news, title: e.target.value })}
                    placeholder="News Title"
                    required
                    className="input input-bordered w-full max-w-xs mb-4"
                />
                <ReactQuill
                    theme="snow"
                    value={news.content}
                    onChange={(value) => setNews({ ...news, content: value })}
                />
                <div className="mb-4">
                    <input
                        type="file"
                        onChange={(e) => setNews({ ...news, photo: e.target.files[0] })}
                        className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent"
                    />
                </div>
                <button type="submit" className="btn btn-success">Add News</button>
            </form>
        </div>
    );
};

export default DataCreatePage;
