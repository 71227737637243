import React from 'react';
import about from '../../../assets/about.jpg';

const About = () => {
    return (
        <>
            <section className="py-10 md:py-16">
                <div className="container">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="text-center md:text-left">
                            <h3 className="text-2xl sm:text-4xl font-bold mb-4">About Us</h3>
                            <p className="text-lg mb-10 md:mb-14">
                                With our extensive experience in infrared thermographic imaging programs, we assist clients in a wide array of industries, including manufacturing, municipal and institutional operations, and conventional power facilities.
                                All services are supported by the company’s ongoing maintenance support that ensures customer sites maintain high operating standards.
                                We assist clients in a wide array of industries, including manufacturing, municipal and institutional operations, and conventional power facilities.
                                Our customers include many leading companies in their lines of business, ranging from large multinationals to smaller national or local companies.
                                What they all have in common, is a preference for quality, reliability, and value for money. <br />
                                A large portion of our customers has had a long-standing relationship with us.
                            </p>
                        </div>
                        <div className="artboard artboard-horizontal">
                            <img src={about} alt="Artboard" style={{ borderRadius: '10px' }} />
                        </div>
                    </div>
                </div>
            </section>

            <br />
            <br />

            <section className="py-10 md:py-16 bg-gray-100">
                <div className="container">
                    <div className="text-center">
                        <h3 className="text-2xl sm:text-4xl font-bold mb-4">Take a look at to our company</h3>
                        <p className="text-lg mb-10 md:mb-14">
                            CBM Engineering laboratory provides a broad scope of accreditation for pressure, temperature, electrical, and mechanical measurands. We are continuously monitoring the market for the demand for new types of measurements,
                            and promptly responding to these needs by expanding the appropriate scope of accreditation.
                        </p>
                        <div className="video-container flex justify-center items-center">
                            <iframe
                                style={{ borderRadius: '2rem' }}
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/Q4Yx_p44-iA"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

// test

export default About;