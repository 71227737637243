import React from 'react';
import backgroundImage from '../../../assets/banner.jpg';

export default function Hero() {
    return (
        <section>
            <div className='container-fluid'>
                <div className="hero h-[300px] lg:h-[400px]" style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <div className="hero-overlay bg-opacity-60"></div>
                    <div className="hero-content text-center text-neutral-content">
                        <div className="max-w-md mx-auto">
                            <h1 className="mb-5 text-3xl lg:text-5xl font-bold">Welcome</h1>
                            <p className="mb-5 text-lg">CBM Engineering, your expert in engineering services for diverse industries.</p>
                            {/* <button className="btn btn-primary">Get Started</button> */}
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </section>
    );
}
