import React from 'react'
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import CTA from "../components/Route/CTA/CTA";
import Services from "../components/Route/Services/Services";
import Footer from "../components/Layout/Footer";
import Partner from '../components/Route/Partners/Partner';
import About from '../components/Route/About/About';
import News from '../components/Route/News/News';

const HomePage = () => {
  return (
    <div>
      <Header activeHeading={1} />
      <Hero />
      <About />
      <Partner />
      <Services />
      <News />
      <CTA />
      <Footer />
    </div>
  )
}

export default HomePage