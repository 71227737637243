import React from 'react'
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Clients from '../components/Route/Clients/Clients';
import NewsList from '../components/Route/NewsList/NewsList';

const NewsAllPage = () => {
    return (
        <div>
            <Header activeHeading={4} />
            <NewsList />
            <Clients />
            <Footer />
        </div>
    )
}

export default NewsAllPage