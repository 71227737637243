import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

export default function Header({ activeHeading }) {
  const [theme, setTheme] = React.useState("light");
  const [services, setServices] = useState([]);
  const [subServices, setSubServices] = useState({});
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const response = await fetch(`${apiUrl}/service/all-services`);
        if (response.ok) {
          const data = await response.json();
          setServices(data.services);
          const initialSubServices = {};
          data.services.forEach(service => {
            initialSubServices[service._id] = [];
            fetchSubServices(service._id);
          });
          setSubServices(initialSubServices);
        } else {
          throw new Error('Services could not be loaded');
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };
    fetchServices();
  }, []);

  const fetchSubServices = async (serviceId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${apiUrl}/service/services/${serviceId}`);
      if (response.ok) {
        const data = await response.json();
        setSubServices(prevSubServices => ({
          ...prevSubServices,
          [serviceId]: data.subServices.map(sub => ({ id: sub._id, title: sub.title }))
        }));
      } else {
        throw new Error('Sub-services could not be loaded');
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  React.useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const handleThemeChange = (e) => {
    const val = e.target.getAttribute("data-set-theme");
    setTheme(val);
  };

  const toggleDropdown = (serviceId) => {
    setOpenDropdown(prev => (prev === serviceId ? null : serviceId));
  };

  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <>
      <header className="bg-base-100 py-2 sticky top-0 z-50">
        <div className="container">
          <div className="navbar px-0">
            <div className="navbar-start">
              <div className="dropdown">
                <label
                  tabIndex={0}
                  className="btn btn-circle btn-primary lg:hidden mr-1"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  <i className="bi bi-list text-2xl"></i>
                </label>
                {menuOpen && (
                  <ul
                    tabIndex={0}
                    className="dropdown-content mt-1 w-52 menu menu-compact p-2 bg-base-200 shadow rounded-box"
                  >
                    <li>
                      <Link to="/" className="link">Home</Link>
                    </li>
                    <li>
                      <Link to="/about" className="link">About</Link>
                    </li>
                    <li>
                      <Link to="/news" className="link">News</Link>
                    </li>
                    <li>
                      <Link to="/contact" className="link">Contact</Link>
                    </li>
                  </ul>
                )}
              </div>
              <Link to="/" className="logo">
                <img src={logo} alt="Logo" />
              </Link>
            </div>

            <div className="navbar-center hidden lg:flex">
              <ul className="menu menu-horizontal p-0">
                <li><Link to="/" className="no-hover text-base text-gray-700 px-3 py-2 rounded-md">Home</Link></li>
                <li><Link to="/about" className="no-hover text-base text-gray-700 px-3 py-2 rounded-md">About</Link></li>
                <li className="dropdown dropdown-hover">
                  <label tabIndex={0} className="no-hover text-base text-gray-700 px-3 py-2 rounded-md">
                    Services
                  </label>
                  <ul className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                    {services.map(service => (
                      <li key={service._id} className="dropdown dropdown-hover" onMouseEnter={() => toggleDropdown(service._id)} onMouseLeave={() => toggleDropdown(null)}>
                        <label tabIndex={0} className="no-hover block cursor-pointer p-2">
                          {service.name}
                        </label>
                        {openDropdown === service._id && subServices[service._id] && (
                          <ul className="dropdown-content sub-menu p-0 shadow bg-base-100 rounded-box w-52">
                            {subServices[service._id].map(sub => (
                              <li key={sub.id}>
                                <Link to={`/service/${service._id}/subservice/${sub.id}`} className="...">
                                  {sub.title}
                                </Link>
                              </li>
                            ))}

                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
                <li><Link to="/news" className="no-hover text-base text-gray-700 px-3 py-2 rounded-md">News</Link></li>
                <li><Link to="/contact" className="no-hover text-base text-gray-700 px-3 py-2 rounded-md">Contact</Link></li>
              </ul>

            </div>

            <div className="navbar-end"></div>
          </div>
        </div>
      </header>
    </>
  );
}
