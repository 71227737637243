import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const ServiceListPage = () => {
    const { serviceId } = useParams();
    const [subServiceTitles, setSubServiceTitles] = useState([]);

    useEffect(() => {
        const fetchSubServiceTitles = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            try {
                const response = await fetch(`${apiUrl}/service/services/${serviceId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch sub-services');
                }
                const data = await response.json();
                setSubServiceTitles(data.subServices);
            } catch (error) {
                console.error('Error fetching sub-services:', error);
            }
        };

        fetchSubServiceTitles();
    }, [serviceId]);

    return (
        <div className='container mx-auto p-4'>
            <h2 className='text-2xl font-bold mb-4'>Services we provide</h2>
            <ol className="list-disc pl-5">
                {subServiceTitles.map((subService, index) => (
                    <li key={index}>
                        <Link to={`/service/${serviceId}/subservice/${subService._id}`} className=" hover:text-blue-700">
                            {subService.title}
                        </Link>
                    </li>
                ))}
            </ol>
        </div>
    );
}

export default ServiceListPage;
