import React from 'react'
import MissionIcon from '../../../assets/icons/mission.png';
import VisionIcon from '../../../assets/icons/shared-vision.png';
import ValueIcon from '../../../assets/icons/value.png';

const Generic = ({ title, iconPath, children }) => {
    return (
        <div className="card lg:card-side bg-base-100 shadow-xl m-2 flex-grow">
            <div className="card-body min-h-[200px]">
                <h2 className="card-title flex items-center">
                    {iconPath && <img src={iconPath} alt={`${title} icon`} className="w-6 h-6 mr-2" />}
                    {title}
                </h2>
                <p className="text-justify">{children}</p>
            </div>
        </div>
    );
};


const InfoCard = () => {
    return (
        <div className='container'>
            <div className="py-10">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <Generic title="Mission" iconPath={MissionIcon}>
                        Our mission is to build solid relationships with our customers and provide them with exceptional service. We aim to implement this by maintaining our values and principles,
                        constantly through applying innovations and based on the experience of individuals
                    </Generic>
                    <Generic title="Vision" iconPath={VisionIcon}>
                        Our strategic vision is to become one of the leading organizations in the local engineering market by maintaining the quality we provide in our services and to expand our services across neighboring countries
                    </Generic>
                    <Generic title="Value" iconPath={ValueIcon}>
                        Stimulate personal development of our employees. Provide integrity and honesty in relationships with our customers and within the company throughout all processes.
                        Provide transparency in relationships with our customers and government agencies
                    </Generic>
                </div>
            </div>
        </div>
    );
};

export default InfoCard